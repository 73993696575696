body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
  /* user-select: none; */
}

body {
  background: #292c2e;
  color: white;
  font-size: 20px;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 1em;
  padding: 3em 0;
}

h1 {
  font-size: 4em;
  font-weight: 800;
  /* margin-top: .5em; */
}

nav {
  display: flex;
  justify-content: space-between;
  background: #000;
  padding: 1em;
}

nav>div {
  display: flex;
  gap: 1em;
}

.link {
  text-decoration: none;
  color: black;
}

.link.active {
  color: rgba(63, 120, 224, 1);
  font-weight: bold;
}

nav select {
  font-size: 15px;
  padding: .3em .5em;
  cursor: pointer;
}

.navbarScrollingDropdown {
  color: white;

}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, rgba(188, 78, 156, 1), rgba(63, 120, 224, 1));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.gradient-text {
  /* Fallback: Set a background color. */

  
  /* Create the gradient. */
  background-image: linear-gradient(100deg, #7f56d9 20%, #243d6b);
 
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;
  font-style: italic;
  font-family: IBM Plex Serif, sans-serif;
  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient-text2 {
  background-image: linear-gradient(100deg, #7f56d9 20%, #243d6b);
  background-size: 100%;
  font-style: italic;
  font-family: IBM Plex Serif, sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient-text3 {
  background-image: linear-gradient(100deg, #4d7abe 20%, #1f407b);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  background-clip: text;
  
}


.masked {
  height: 100px;
  mask-size: contain;
  mask-repeat: no-repeat;
  background-image: linear-gradient(100deg, #7f56d9 20%, #243d6b);
  -webkit-mask-image: url('../src/images/fleche-gauche.png');
  mask-image: url('../src/images/fleche-gauche.png');
}

.masked1 {
  height: 100px;
  mask-size: contain;
  mask-repeat: no-repeat;
  background-image: linear-gradient(100deg, rgba(63, 120, 224, 1) 20%, #243d6b);
  -webkit-mask-image: url('../src/images/right_13.png');
  mask-image: url('../src/images/right_13.png');
}

.joinBtn:hover {
  background-color: white;
  box-shadow: 0 5px 35px rgba(30, 34, 40, .2)
}

.join1Btn1:hover {
  box-shadow: 10px 10px 35px rgba(30, 34, 40, .2)
}

