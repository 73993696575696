.navitem:global(.active) {
  background: linear-gradient(to bottom, #007F8B 0%,#988EFA 100%);
}

.outer-container {
  width: 100%; /* Largeur du conteneur extérieur */
  height: 800px; /* Hauteur du conteneur extérieur */
  overflow-y: scroll; /* Défilement vertical pour le conteneur extérieur */
}

.inner-container {
  width: calc(100% - 17px); /* Largeur du conteneur intérieur (ajustée pour compenser la barre de défilement) */
}

.scrollable-y {
  overflow-y: scroll; /* Défilement vertical pour le conteneur intérieur */
  height: 100%; /* Hauteur du conteneur intérieur */
}


.gradient-text {
  /* Fallback: Set a background color. */

  
  /* Create the gradient. */
  background-image: linear-gradient(100deg, #7f56d9 20%, #243d6b);
 
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;
  font-style: italic;
  font-family: IBM Plex Serif, sans-serif;
  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient-text2 {
  background-image: linear-gradient(100deg, #7f56d9 20%, #243d6b);
  background-size: 100%;
  font-style: italic;
  font-family: IBM Plex Serif, sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.share{
 padding-top: 200px;
}

.videop{
   padding: 0% 8%; 
}

.badge {
  visibility: visible;
}

.radius{
  border-radius: 30px;
  border: 3px solid #febf01;
  vertical-align: middle
}

.bar {
  width: 35px;
  height:12px;
}

.applyZIndex {
  filter : brightness(80%) grayscale(15%);
 }

 

.radius:hover{
  background: linear-gradient(to bottom, #007F8B 0%,#988EFA 100%);
}


.fond_image img{

  -webkit-filter : saturate(0%); /* Chrome, Safari, Opera */
  filter : saturate(0%);
  
}

.fond_image img:hover{

  -webkit-filter : saturate(100%); /* Chrome, Safari, Opera */
  filter : saturate(100%);

}

.fond_image1 img{

  -webkit-filter : brightness(65%) grayscale(35%);/* Chrome, Safari, Opera */
  filter : brightness(65%) grayscale(35%);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  
  
  }



}



.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.flash{
  position:fixed;
  z-index: 3;
  animation: slidein 2s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -4s);

  animation-iteration-count: 1;
  animation-fill-mode: both;
  }

@keyframes slidein {
  from {
   bottom: 10px;
   right:-100px;
    
  }

  to {
    bottom: 10px;
   right:10px;
    
    
  }
}

.img-rectangle {
  clip-path:ellipse(33% 50%);
  }

.list{
  
  transition: transform .5s;
  }

.list:hover{
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  }

  

  .slogan {
  font-size: 3.8em;
}

@media screen and (max-width: 992px) {
  .flash {
    visibility: hidden;
  }
  .bar {
    width: 70%;
   margin: auto;
   height:6px;
  }


  .share{
    padding-top: 0px;
   }
   
   .badge {
  visibility: hidden;
}
.btn1 {
    position: relative;
    padding:0.5em 0.5em ;
    overflow: hidden;
   
  }

  .slogan {
    font-size: 2.5em;
  }
}



@media (min-width: 992px) and (max-width: 1630px) {
  
  .btn1 {
    position: relative;
    padding:0.5em 0.5em ;
    overflow: hidden;
   
  }

  .slogan {
    font-size: 3em;
  }

}





* {
  box-sizing: border-box;
}





.btn1 {
  position: relative;
  padding:0.5em 0.5em ;
  overflow: hidden;
 
}




.btn1:before, .btn1:after {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: transparent;
}
.btn1:before {
  top: 0;
}
.btn1:after {
  bottom: 0;
}
.btn1:hover > * > *:before, .btn1:hover > * > *:after {
  transform: translate3d(0, 0, 0);
}
.btn1 > * > *:before, .btn1 > * > *:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 9;
  height: 4px;
  width: 100%;
  background-color: white;
}
.btn1 > * > *:before {
  bottom: 0;
  transform: translate3d(-25%, 0, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn1 > * > *:after {
  top: 0;
  transform: translate3d(25%, 0, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn1:hover > * > * > *:before, .btn1:hover > * > * > *:after {
  transform: translate3d(0, 0, 0);
}

/*  coté */

.btn1 > *:before, .btn1 > *:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: transparent;
}
.btn1 > *:before {
  left: 0;
}
.btn1 > *:after {
  right: 0;
}

.btn1 > * > * > *:before, .btn1 > * > * > *:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 9;
  height: 100%;
  width: 4px;
  background-color: white;
}
.btn1 > * > * > *:before {
  /*  gauche */
  left: 0;
  transform: translate3d(0, 75%, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn1 > * > * > *:after {
  /*  DROITE */
  right: 0;
  transform: translate3d(0, -75%, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter{
   opacity: 0;
}
.fade-exit{
   opacity: 1;
}
.fade-enter-active{
   opacity: 1;
}
.fade-exit-active{
   opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
   transition: opacity 500ms;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}